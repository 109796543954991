<template>
  <div>
    <div
      class="form--group"
      v-if="bookingInfo && bookingInfo.listing && !bookingInfo.listing.view_to_offer"
    >
      <div class="left--col">
        <span>{{ $t('general.useNego') }}</span>
      </div>
      <div class="right--col">
        <div class="custom-control custom-checkbox mt-4">
          <input
            type="checkbox"
            class="custom-control-input"
            id="use-nego"
            name="use-nego"
            v-model="useNego"
          />
          <label class="custom-control-label" for="use-nego"></label>
        </div>
      </div>
    </div>
    <div
      v-show="useNego || (bookingInfo && bookingInfo.listing && bookingInfo.listing.view_to_offer)"
    >
      <!-- The line above this must use v-show instead of v-if, because the newPricePsf field is buggy when using v-if. -->
      <v-card class="nego--instruction">
        {{
          bookingInfo && bookingInfo.listing && bookingInfo.listing.view_to_offer
            ? $t('booking.negoViewToOfferInstructionSell')
            : $t('booking.negoInstructionSell')
        }}
      </v-card>
      <div class="form--group" :class="{ 'has-error': validation.hasError('newPrice') }">
        <div class="left--col">
          <label for="newPrice">
            <span>{{ $t('general.finalPrice') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <div class="input--group">
            <div class="append--left">
              S$
            </div>
            <cleave
              class="basic--input price--input"
              type="text"
              name="new_price"
              v-model="newPrice"
              :options="cleaveOption"
              ref="price"
              id="newPrice"
            />
          </div>
          <span class="val-error mt-2" v-if="validation.hasError('newPrice')">{{
            validation.firstError('newPrice')
          }}</span>
        </div>
      </div>
      <div class="form--group">
        <div class="left--col">
          <span>{{ `${$t('general.pricePsf')}` }}</span>
        </div>
        <div class="right--col">
          <div class="input--group">
            <div class="append--left">
              S$
            </div>
            <cleave
              class="basic--input price--input"
              type="text"
              name="new_price"
              :value="newPricePsf"
              :options="cleaveOptionPsf"
              readonly
            />
            <div class="append--right">
              / sqft
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('fullName') }">
      <div class="left--col">
        <label for="fullname">
          <span>{{ $t('profile.name') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <input id="fullname" v-model="fullName" class="basic--input" />
        <span class="val-error mt-2" v-if="validation.hasError('fullName')">{{
          validation.firstError('fullName')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('phone') }">
      <div class="left--col">
        <label for="phone">
          <span>{{ $t('profile.phone') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <!--            <cleave-->
        <!--              id="phone"-->
        <!--              v-model="phone"-->
        <!--              :options="phoneCleaveOption"-->
        <!--              class="basic&#45;&#45;input"-->
        <!--              type="text"-->
        <!--              name="phone"-->
        <!--            />-->
        <client-only>
          <vue-tel-input
            id="phone"
            :value="phone ? phone : ''"
            @input="phoneInput"
            :validCharactersOnly="true"
            defaultCountry="sg"
            class="basic--input"
            style="padding: 4px;"
          />
        </client-only>
        <span class="val-error mt-2" v-if="validation.hasError('phone')">{{
          validation.firstError('phone')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('email') }">
      <div class="left--col">
        <label for="email">
          <span>{{ $t('loginRegister.email') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <input id="email" type="email" v-model="email" class="basic--input" />
        <span class="val-error mt-2" v-if="validation.hasError('email')">{{
          validation.firstError('email')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('ktp') }">
      <div class="left--col">
        <label for="ktp">
          <span>{{ $t('profile.ktp') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <input id="ktp" @keypress="isNumber" v-model="ktp" class="basic--input" />
        <span class="val-error mt-2" v-if="validation.hasError('ktp')">{{
          validation.firstError('ktp')
        }}</span>
      </div>
    </div>
    <ktp-photo ref="ktpPhotoField" />
    <offer-letter ref="offerLetterField" />
    <!--    <div class="form&#45;&#45;group" :class="{ 'has-error': validation.hasError('npwp') }">-->
    <!--      <div class="left&#45;&#45;col">-->
    <!--        <label for="npwp">-->
    <!--          <span>{{ $t('profile.npwp') }}</span>-->
    <!--          <span class="required">*</span>-->
    <!--        </label>-->
    <!--      </div>-->
    <!--      <div class="right&#45;&#45;col">-->
    <!--        <input id="npwp" @keypress="isNumber" v-model="npwp" class="basic&#45;&#45;input" />-->
    <!--        <span class="val-error" v-if="validation.hasError('npwp')">{{-->
    <!--          validation.firstError('npwp')-->
    <!--        }}</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <npwp-photo ref="npwpPhotoField" />-->
    <!--    <div class="form&#45;&#45;group" :class="{ 'has-error': validation.hasError('address') }">-->
    <!--      <div class="left&#45;&#45;col">-->
    <!--        <label for="address">-->
    <!--          <span>{{ $t('profile.address2') }}</span>-->
    <!--          <span class="required">*</span>-->
    <!--        </label>-->
    <!--      </div>-->
    <!--      <div class="right&#45;&#45;col">-->
    <!--        <textarea id="address" v-model="address" class="basic&#45;&#45;input" rows="3" />-->
    <!--        <span class="val-error" v-if="validation.hasError('address')">{{-->
    <!--          validation.firstError('address')-->
    <!--        }}</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <regional-form ref="regionalForm" :village-required="true"></regional-form>-->
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';

// const RegionalForm = () => import('@/components/utils/partials/regional-form.vue');
import { mapState } from 'vuex';

import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.id.js';
const KtpPhoto = () => import('@/components/checkout/fields/ktp-photo');
// const NpwpPhoto = () => import('@/components/checkout/fields/npwp-photo.vue');
const OfferLetter = () => import('@/components/checkout/fields/offer-letter');
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
export default {
  name: 'billing-information',
  components: {
    // RegionalForm,
    Cleave,
    // NpwpPhoto,
    KtpPhoto,
    VueTelInput,
    OfferLetter,
  },
  data() {
    return {
      // phoneCleaveOption: {
      //   phone: true,
      //   phoneRegionCode: 'id',
      // },
      cleaveOption: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 16,
        numeralDecimalScale: 0,
      },
      cleaveOptionPsf: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 16,
        numeralDecimalScale: 0,
      },
      useNego: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.global.user,
      // address: state => state.checkout.billingInfo.address,
      // fullName: state => state.checkout.billingInfo.fullName,
      // ktp: state => state.checkout.billingInfo.ktp,
      // phone: state => state.checkout.billingInfo.phone,
      // npwp: state => state.checkout.billingInfo.npwp,
      initRegionalData: state => state.checkout.billingInfo.initRegionalData,
      bookingInfo: state => state.checkout.bookingInfo,
    }),
    // address: {
    //   get() {
    //     return this.$store.state.checkout.billingInfo.address;
    //   },
    //   set(val) {
    //     this.$store.commit('checkout/billingInfo/SET_ADDRESS', val);
    //   },
    // },
    newPrice: {
      get() {
        return this.$store.state.checkout.billingInfo.newPrice;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_NEW_PRICE', val);
      },
    },
    fullName: {
      get() {
        return this.$store.state.checkout.billingInfo.fullName;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_FULL_NAME', val);
      },
    },
    ktp: {
      get() {
        return this.$store.state.checkout.billingInfo.ktp;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_KTP', val);
      },
    },
    phone: {
      get() {
        return this.$store.state.checkout.billingInfo.phone;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_PHONE', val);
      },
    },
    phoneCountryCode: {
      get() {
        return this.$store.state.checkout.billingInfo.phoneCountryCode;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_PHONE_COUNTRY_CODE', val);
      },
    },
    email: {
      get() {
        return this.$store.state.checkout.billingInfo.email;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_EMAIL', val);
      },
    },
    // npwp: {
    //   get() {
    //     return this.$store.state.checkout.billingInfo.npwp;
    //   },
    //   set(val) {
    //     this.$store.commit('checkout/billingInfo/SET_NPWP', val);
    //   },
    // },
    loading: {
      get() {
        return this.$store.state.v2.booking.billingInfoLoading;
      },
      set(val) {
        this.$store.commit('v2/booking/SET_BILLING_INFO_LOADING', val);
      },
    },
    newPricePsf() {
      if (
        this.newPrice &&
        this.bookingInfo &&
        this.bookingInfo.listing &&
        this.bookingInfo.listing.size_in_square_foot
      ) {
        let result =
          parseFloat(this.newPrice) / parseFloat(this.bookingInfo.listing.size_in_square_foot);
        return parseInt(Math.ceil(result * 100) / 100);
      } else {
        return 0;
      }
    },
  },
  watch: {
    useNego(val) {
      this.onUseNegoChange(val);
    },
  },
  validators: {
    newPrice(value) {
      return Validator.value(value).required(this.$i18n.t('errors.finalPrice.required'));
    },
    fullName(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.name.required'))
        .maxLength(50, this.$i18n.t('errors.name.max', { maxChar: 50 }));
    },
    ktp(value) {
      return Validator.value(value).required(this.$i18n.t('errors.identity_card_number.required'));
      // .length(16, this.$i18n.t('errors.identity_card_number.length', { char: 16 }));
    },
    // npwp(value) {
    //   return Validator.value(value)
    //     .required(this.$i18n.t('errors.npwp.required'))
    //     .maxLength(15, this.$i18n.t('errors.npwp.max', { maxChar: 15 }));
    // },
    phone(value) {
      value = value !== undefined ? value : null;
      return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
    },
    // address(value) {
    //   return Validator.value(value)
    //     .required(this.$i18n.t('errors.address.required'))
    //     .maxLength(250, this.$i18n.t('errors.address.max', { maxChar: 250 }));
    // },
    email(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.email.required'))
        .email(this.$i18n.t('errors.loginRegister.email.invalid'))
        .maxLength(255, this.$i18n.t('errors.loginRegister.email.max', { maxChar: 255 }));
    },
  },
  methods: {
    phoneInput(phone, phoneObject) {
      if (phoneObject && phoneObject.countryCode) {
        this.phoneCountryCode = phoneObject.countryCode;
      } else {
        this.phoneCountryCode = null;
      }
      this.phone = phone;
    },
    validate() {
      let validationArray = [
        this.$validate(),
        // this.$refs.npwpPhotoField.validate(),
        this.$refs.ktpPhotoField.validate(),
        this.$refs.offerLetterField.validate(),
      ];
      return Promise.all(validationArray).then(function(results) {
        if (
          results.filter(function(result) {
            return !result;
          }).length === 0
        ) {
          return true;
        }
        return false;
      });
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async getProfileUser(category) {
      category = category === undefined ? 'profile' : category;
      if (category === 'profile') {
        this.fullname = this.user.fullname;
        this.identity_card_number = this.user.identity_card_number;
        this.phone = this.user.phone;
        this.address_line_2 = this.user.address_line_2;
      }

      if (category === 'bill') {
        let response = await this.$store.dispatch('v2/profile/getBillingInformation');
        if (response === 'success') {
          let data = response.data;
          this.fullname = data.fullname;
          this.identity_card_number = data.identity_card_number;
          this.phone = data.phone;
          this.address_line_2 = data.address_line_2;
        } else {
          this.fullname = '';
          this.identity_card_number = '';
          this.phone = '';
          this.address_line_2 = '';
        }
      }
    },
    async applyRegionalData() {
      //   this.$refs.regionalForm.provinces = this.initRegionalData.provinces;
      //   this.$refs.regionalForm.cities = this.initRegionalData.cities;
      //   this.$refs.regionalForm.districts = this.initRegionalData.districts;
      //   this.$refs.regionalForm.villages = this.initRegionalData.villages;
      //
      //   if (this.initRegionalData.province != null) {
      //     this.$refs.regionalForm.province = this.initRegionalData.provinces.find(item => {
      //       return item.id === this.initRegionalData.province;
      //     });
      //   }
      //   if (this.initRegionalData.city != null) {
      //     this.$refs.regionalForm.city = this.initRegionalData.cities.find(item => {
      //       return item.id === this.initRegionalData.city;
      //     });
      //   }
      //   if (this.initRegionalData.district != null) {
      //     this.$refs.regionalForm.district = this.initRegionalData.districts.find(item => {
      //       return item.id === this.initRegionalData.district;
      //     });
      //   }
      //   if (this.initRegionalData.village != null) {
      //     this.$refs.regionalForm.village = this.initRegionalData.villages.find(item => {
      //       return item.id === this.initRegionalData.village;
      //     });
      //   }
    },
    async getProfile(category) {
      await this.getRegionalData(category);

      await this.getProfileUser(category);

      this.category = category;
    },
    // eslint-disable-next-line no-unused-vars
    onUseNegoChange(val) {
      if (this.bookingInfo && this.bookingInfo.listing) {
        this.newPrice = this.bookingInfo.listing.sell_price_raw;
      }
    },
  },
};
</script>

<style scoped></style>
